/**
	@see https://github.com/michalsnik/aos#customizing-default-animations-distance
 */
$aos-distance: 100px;

@import 'aos/src/sass/aos.scss';

/*****************	custom transitions	**************************/

[data-aos="header-in"] {
	transform: translate(-200px, 0);

	&:before {
		opacity: 0;
		transform: rotateZ(180deg) scale(15) translate(0, 0);
		transition: transform 1s ease, opacity 1s ease;
	}

	&.aos-animate {
		transform: translate(0, 0);

		&:before {
			opacity: 1;
			transform: rotateZ(180deg) scale(1) translate(0, 0);
		}
	}
}

// #############################################################

[data-aos="blur-slide-left"] {
	filter: blur(10px);
	transform: translate(50px, 0);
	opacity: 0;
	transition-property: filter, transform, opacity;

	&.aos-animate {
		filter: blur(0px);
		transform: translate(0, 0);
		opacity: 1;
	}
}

[data-aos="blur-slide-right"] {
	filter: blur(10px);
	transform: translate(-50px, 0);
	opacity: 0;
	transition-property: filter, transform, opacity;

	&.aos-animate {
		filter: blur(0px);
		transform: translate(0, 0);
		opacity: 1;
	}
}

[data-aos="blur-slide-up"] {
	filter: blur(10px);
	transform: translate(0, 50px);
	opacity: 0;
	transition-property: filter, transform, opacity;

	&.aos-animate {
		filter: blur(0px);
		transform: translate(0, 0);
		opacity: 1;
	}
}

// #############################################################

[data-aos="blur-zoom-in-slide-left"] {
	filter: blur(10px);
	transform: translate(100px, 0) scale(2.5);
	opacity: 0;
	transition-property: filter, transform, opacity;

	&.aos-animate {
		filter: blur(0px);
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

[data-aos="blur-zoom-in-slide-right"] {
	filter: blur(10px);
	transform: translate(-100px, 0) scale(2.5);
	opacity: 0;
	transition-property: filter, transform, opacity;

	&.aos-animate {
		filter: blur(0px);
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

// #############################################################

[data-aos="blur-zoom-in"] {
	filter: blur(10px);
	transform: scale(1.5);
	opacity: 0;
	transition-property: filter, transform, opacity;

	&.aos-animate {
		filter: blur(0px);
		transform: scale(1);
		opacity: 1;
	}
}

