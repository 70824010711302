@import 'dropzone/src/dropzone.scss';

.dropzone {
	border: 5px dashed rgba(255, 255, 255, 0.5);
	background: rgba(0, 0, 0, 0.2);
	transition: background 0.25s;

	&:hover {
		background: rgba(0, 0, 0, 0.5);
	}

	.dz-message {
		width: 100%;
		height: 100%;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		row-gap: 10px;
	}

	.dz-preview {
		.dz-image {
			border-radius: 0;
		}

		&.dz-file-preview {
			.dz-image {
				border-radius: 0;
			}
		}

		&.dz-processing {
			.dz-progress {
				border-radius: 0;

				.dz-upload {
					border-radius: 0;
				}
			}
		}
	}
}